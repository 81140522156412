// ************************************
//                       main js
// ************************************
$(function () {
  'use strict'
$(document).ready(function () {
console.log('message')
 const homeSwiper = new Swiper('.homeSwiper', {
  parallax: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  autoHeight: true,
  // autoplay: true,
  // zoom: true,
  // loop: true,
  preloadImages: false,
  lazy: true,
  Height: 400,
  speed: 3000
})
})

})



